// ----------------------------------------
// CP Logo Icon Font
// ----------------------------------------
$cplogo-family: "CPLogo" !default;
$cplogo-symbol: \e929;
$cplogo-logo: \e902;
$cplogo-reach: \e901;
$cplogo-myfuture: \e900;
$cplogo-workxperience: \e903;
$cplogo-careerexplorer-symbol: \e904;
$cplogo-careerexplorer: \e905;
$cplogo-coursefinder-symbol: \e906;
$cplogo-coursefinder: \e907;
@font-face {
    font-family: '#{$cplogo-family}';
    src: url('#{$fontpath}/#{$cplogo-family}.eot?h30fmb');
    src: url('#{$fontpath}/#{$cplogo-family}.eot?h30fmb#iefix') format('embedded-opentype'),
    url('#{$fontpath}/#{$cplogo-family}.ttf?h30fmb') format('truetype'),
    url('#{$fontpath}/#{$cplogo-family}.woff?h30fmb') format('woff'),
    url('#{$fontpath}/#{$cplogo-family}.svg?h30fmb##{$cplogo-family}') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

[class^="cplogo-"],
[class*=" cplogo-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: '#{$cplogo-family}' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.cplogo-symbol {
    &:before {
        content: unicode($cplogo-symbol);
    }
}

.cplogo-logo {
    &:before {
        content: unicode($cplogo-logo);
    }
}

.cplogo-reach {
    &:before {
        content: unicode($cplogo-reach);
    }
}

.cplogo-myfuture {
    &:before {
        content: unicode($cplogo-myfuture);
    }
}

.cplogo-workxperience {
    &:before {
        content: unicode($cplogo-workxperience);
    }
}

.cplogo-careerexplorer-symbol {
    &:before {
        content: unicode($cplogo-careerexplorer-symbol);
    }
}

.cplogo-careerexplorer {
    &:before {
        content: unicode($cplogo-careerexplorer);
    }
}

.cplogo-coursefinder-symbol {
    &:before {
        content: unicode($cplogo-coursefinder-symbol);
    }
}

.cplogo-coursefinder {
    &:before {
        content: unicode($cplogo-coursefinder);
    }
}