$fontpath: "../files/fonts" !default;

@import "../iconfonts/cplogoiconfont";
@import "../iconfonts/cpiconfont";
@import "../iconfonts/cptoolboxiconfont";
@import "../iconfonts/cpzoneiconfont";
@import "../iconfonts/cpleveliconfont";
@import "../iconfonts/apprenticeshipiconfont";


@font-face {
    font-family: 'Neue Machina Regular';
    src: url('#{$fontpath}/neuemachina-regular.woff2') format('woff2'),
         url('#{$fontpath}/neuemachina-regular.woff') format('woff'),
         url('#{$fontpath}/neuemachina-regular.ttf') format('truetype'),
         url('#{$fontpath}/neuemachina-regular.svg#neue_machinaregular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Neue Machina Ultrabold';
    src: url('#{$fontpath}/neuemachina-ultrabold.woff2') format('woff2'),
         url('#{$fontpath}/neuemachina-ultrabold.woff') format('woff'),
         url('#{$fontpath}/neuemachina-ultrabold.ttf') format('truetype'),
         url('#{$fontpath}/neuemachina-ultrabold.svg#neue_machinaultrabold') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
$neueregular: 'Neue Machina Regular';
$neuebold: 'Neue Machina Ultrabold';