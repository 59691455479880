// Type Fonts
$primary-font-family:       'Lato', sans-serif;
$secondary-font-family:     'Montserrat', sans-serif;
$mono-font-family:          'Overpass Mono', monospace;

// Icon Fonts
$zmdi:                      'Material-Design-Iconic-Font';
$cplogo:                    'CPLogo';
$cpicons:                   'CareersPortal';
$cptoolbox:                 'CPToolbox';
$cpzone:                    'CPZone';
$cplevel:                   'CPLevel';

// Base Fonts
$base-font-size:            16px;
$base-font-family:          $primary-font-family;           $base-font-weight:          400;
$heading-font-family:       $secondary-font-family;         $heading-font-weight:       700;

$default-font-size:(
    h1: 2.25em,
    h2: 1.5em,
    h3: 1.3215em,
    h4: 1.25em,
    h5: 1em,
    h6: 0.85em,
    p: 1em,
    blockquote: 1em,
    dl: 1em,
    ol: 1em,
    ul: 1em
);

$default-line-height:(
    h1: 1.3333333333333333,
    h2: 1.3333333333333333,
    h3: 1.1428571428571428,
    h4: 1.3333333333333333,
    h5: 1.5,
    h6: 1.25,
    p: 1.5,
    blockquote: 1.5,
    dd: 1.5,
    dt: 1.5,
    li: 1.5
);

$default-margin-bottom:(
    h1: 0.6666666666666666em,
    h2: 1.3333333333333333em,
    h3: 1.1428571428571428em,
    h4: 1.3333333333333333em,
    h5: 1.5em,
    h6: 1.25em,
    p: 1.5em,
    blockquote: 1.5em,
    dl: 1.5em,
    ol: 1.5em,
    ul: 1.5em
);