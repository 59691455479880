// ----------------------------------------
// CP Level Icon Font
// ----------------------------------------
$cplevel-family: "CPLevel" !default;
$cplevel-0: \e900;
$cplevel-1: \e901;
$cplevel-2: \e902;
$cplevel-3: \e903;
$cplevel-4: \e904;
$cplevel-5: \e905;
$cplevel-6: \e906;
$cplevel-7: \e907;
$cplevel-8: \e908;
$cplevel-9: \e909;
$cplevel-10: \e90a;
@font-face {
    font-family: '#{$cplevel-family}';
    src: url('#{$fontpath}/#{$cplevel-family}.eot?wy5fg6');
    src: url('#{$fontpath}/#{$cplevel-family}.eot?wy5fg6#iefix') format('embedded-opentype'),
    url('#{$fontpath}/#{$cplevel-family}.ttf?wy5fg6') format('truetype'),
    url('#{$fontpath}/#{$cplevel-family}.woff?wy5fg6') format('woff'),
    url('#{$fontpath}/#{$cplevel-family}.svg?wy5fg6##{$cplevel-family}') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

[class^="cplevel-"],
[class*=" cplevel-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: '#{$cplevel-family}' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.cplevel-0 {
    &:before {
        content: unicode($cplevel-0);
    }
}

.cplevel-1 {
    &:before {
        content: unicode($cplevel-1);
    }
}

.cplevel-2 {
    &:before {
        content: unicode($cplevel-2);
    }
}

.cplevel-3 {
    &:before {
        content: unicode($cplevel-3);
    }
}

.cplevel-4 {
    &:before {
        content: unicode($cplevel-4);
    }
}

.cplevel-5 {
    &:before {
        content: unicode($cplevel-5);
    }
}

.cplevel-6 {
    &:before {
        content: unicode($cplevel-6);
    }
}

.cplevel-7 {
    &:before {
        content: unicode($cplevel-7);
    }
}

.cplevel-8 {
    &:before {
        content: unicode($cplevel-8);
    }
}

.cplevel-9 {
    &:before {
        content: unicode($cplevel-9);
    }
}

.cplevel-10 {
    &:before {
        content: unicode($cplevel-10);
    }
}