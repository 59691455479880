// ----------------------------------------
// CP Icon Font
// ----------------------------------------
$cpfont-family: 'CareersPortal' !default;
$cp-administrative-icon: \e900;
$cp-creative-icon: \e901;
$cp-enterprising-icon: \e902;
$cp-investigative-icon: \e903;
$cp-linguistic-icon: \e904;
$cp-naturalist-icon: \e905;
$cp-realist-icon: \e906;
$cp-social-icon: \e907;
$cp-accountancy-icon: \e908;
$cp-banking-icon: \e909;
$cp-insurance-icon: \e90a;
$cp-animals-icon: \e90b;
$cp-farming-icon: \e90c;
$cp-food-icon: \e90d;
$cp-maritime-icon: \e90e;
$cp-advertising-icon: \e90f;
$cp-business-icon: \e910;
$cp-clerical-icon: \e911;
$cp-sales-icon: \e912;
$cp-tourism-icon: \e913;
$cp-transport-icon: \e914;
$cp-art-icon: \e915;
$cp-fashion-icon: \e916;
$cp-media-icon: \e917;
$cp-music-icon: \e918;
$cp-community-icon: \e919;
$cp-education-icon: \e91a;
$cp-government-icon: \e91b;
$cp-law-icon: \e91c;
$cp-security-icon: \e91d;
$cp-history-icon: \e91e;
$cp-leisure-icon: \e91f;
$cp-medical-icon: \e920;
$cp-psychology-icon: \e921;
$cp-architecture-icon: \e922;
$cp-biological-icon: \e923;
$cp-biomedical-icon: \e924;
$cp-computers-icon: \e925;
$cp-earth-icon: \e926;
$cp-engineering-icon: \e927;
$cp-physics-icon: \e928;
@font-face {
    font-family: 'CareersPortal';
    src: url('#{$fontpath}/CareersPortal.eot?qrt0xc');
    src: url('#{$fontpath}/CareersPortal.eot?qrt0xc#iefix') format('embedded-opentype'),
    url('#{$fontpath}/CareersPortal.ttf?qrt0xc') format('truetype'),
    url('#{$fontpath}/CareersPortal.woff?qrt0xc') format('woff'),
    url('#{$fontpath}/CareersPortal.svg?qrt0xc#CareersPortal') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

[class^="cp-"],
[class*=" cp-"] {
    font-family: 'CareersPortal' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

$cpicons-font: 'CareersPortal' !important;
.cp-interest-2,
.cp-administrative-icon {
    &:before {
        content: unicode($cp-administrative-icon);
    }
}

.cp-interest-6,
.cp-creative-icon {
    &:before {
        content: unicode($cp-creative-icon);
    }
}

.cp-interest-3,
.cp-enterprising-icon {
    &:before {
        content: unicode($cp-enterprising-icon);
    }
}

.cp-interest-4,
.cp-investigative-icon {
    &:before {
        content: unicode($cp-investigative-icon);
    }
}

.cp-interest-7,
.cp-linguistic-icon {
    &:before {
        content: unicode($cp-linguistic-icon);
    }
}

.cp-interest-8,
.cp-naturalist-icon {
    &:before {
        content: unicode($cp-naturalist-icon);
    }
}

.cp-interest-1,
.cp-realist-icon {
    &:before {
        content: unicode($cp-realist-icon);
    }
}

.cp-interest-5,
.cp-social-icon {
    &:before {
        content: unicode($cp-social-icon);
    }
}

.cp-id-32,
.cp-accountancy-icon {
    &:before {
        content: unicode($cp-accountancy-icon);
    }
}

.cp-id-14,
.cp-banking-icon {
    &:before {
        content: unicode($cp-banking-icon);
    }
}

.cp-id-26,
.cp-insurance-icon {
    &:before {
        content: unicode($cp-insurance-icon);
    }
}

.cp-id-31,
.cp-animals-icon {
    &:before {
        content: unicode($cp-animals-icon);
    }
}

.cp-id-2,
.cp-farming-icon {
    &:before {
        content: unicode($cp-farming-icon);
    }
}

.cp-id-25,
.cp-food-icon {
    &:before {
        content: unicode($cp-food-icon);
    }
}

.cp-id-29,
.cp-maritime-icon {
    &:before {
        content: unicode($cp-maritime-icon);
    }
}

.cp-id-1,
.cp-advertising-icon {
    &:before {
        content: unicode($cp-advertising-icon);
    }
}

.cp-id-5,
.cp-business-icon {
    &:before {
        content: unicode($cp-business-icon);
    }
}

.cp-id-9,
.cp-clerical-icon {
    &:before {
        content: unicode($cp-clerical-icon);
    }
}

.cp-id-22,
.cp-sales-icon {
    &:before {
        content: unicode($cp-sales-icon);
    }
}

.cp-id-16,
.cp-tourism-icon {
    &:before {
        content: unicode($cp-tourism-icon);
    }
}

.cp-id-27,
.cp-transport-icon {
    &:before {
        content: unicode($cp-transport-icon);
    }
}

.cp-id-3,
.cp-art-icon {
    &:before {
        content: unicode($cp-art-icon);
    }
}

.cp-id-13,
.cp-fashion-icon {
    &:before {
        content: unicode($cp-fashion-icon);
    }
}

.cp-id-20,
.cp-media-icon {
    &:before {
        content: unicode($cp-media-icon);
    }
}

.cp-id-12,
.cp-music-icon {
    &:before {
        content: unicode($cp-music-icon);
    }
}

.cp-id-36,
.cp-community-icon {
    &:before {
        content: unicode($cp-community-icon);
    }
}

.cp-id-10,
.cp-education-icon {
    &:before {
        content: unicode($cp-education-icon);
    }
}

.cp-id-7,
.cp-government-icon {
    &:before {
        content: unicode($cp-government-icon);
    }
}

.cp-id-17,
.cp-law-icon {
    &:before {
        content: unicode($cp-law-icon);
    }
}

.cp-id-23,
.cp-security-icon {
    &:before {
        content: unicode($cp-security-icon);
    }
}

.cp-id-30,
.cp-history-icon {
    &:before {
        content: unicode($cp-history-icon);
    }
}

.cp-id-18,
.cp-leisure-icon {
    &:before {
        content: unicode($cp-leisure-icon);
    }
}

.cp-id-15,
.cp-medical-icon {
    &:before {
        content: unicode($cp-medical-icon);
    }
}

.cp-id-24,
.cp-psychology-icon {
    &:before {
        content: unicode($cp-psychology-icon);
    }
}

.cp-id-4,
.cp-architecture-icon {
    &:before {
        content: unicode($cp-architecture-icon);
    }
}

.cp-id-6,
.cp-biological-icon {
    &:before {
        content: unicode($cp-biological-icon);
    }
}

.cp-id-34,
.cp-biomedical-icon {
    &:before {
        content: unicode($cp-biomedical-icon);
    }
}

.cp-id-8,
.cp-computers-icon {
    &:before {
        content: unicode($cp-computers-icon);
    }
}

.cp-id-33,
.cp-earth-icon {
    &:before {
        content: unicode($cp-earth-icon);
    }
}

.cp-id-19,
.cp-engineering-icon {
    &:before {
        content: unicode($cp-engineering-icon);
    }
}

.cp-id-21,
.cp-physics-icon {
    &:before {
        content: unicode($cp-physics-icon);
    }
}