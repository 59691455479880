// ----------------------------------------
// Apprenticeships Icon Font
// ----------------------------------------
$apfont-family: "Apprenticeships" !default;
$aplogo-thin: \e900;
$aplogo-bold: \e901;
@font-face {
    font-family: '#{$apfont-family}';
    src: url('#{$fontpath}/#{$apfont-family}.eot?8b321g');
    src: url('#{$fontpath}/#{$apfont-family}.eot?8b321g#iefix') format('embedded-opentype'),
    url('#{$fontpath}/#{$apfont-family}.ttf?8b321g') format('truetype'),
    url('#{$fontpath}/#{$apfont-family}.woff?8b321g') format('woff'),
    url('#{$fontpath}/#{$apfont-family}.svg?8b321g##{$apfont-family}') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

[class^="aplogo-"],
[class*=" aplogo-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: '#{$apfont-family}' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.aplogo-thin {
    &:before {
        content: unicode($aplogo-thin);
    }
}

.aplogo-bold {
    &:before {
        content: unicode($aplogo-bold);
    }
}