// settings
@import "settings/mixins";
@import "settings/colors";
@import "settings/fonts";
@import "settings/icons";
@import "settings/typography";
@import "settings/variables";

//components
@import "components/searchbox";

.cpbulba-overlay {
    &:not(.active) {
        display: none;
    }
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba($background-color2, 0.95);
    z-index: 9999;
    .header {
        background: $acqua;
        display: flex;
        justify-content: space-between;
        font-weight: $base-font-weight;
        font-size: 1rem;
        padding: 0;
        margin: 0;
        a {
            font-family: $heading-font-family;
            font-weight: $heading-font-weight;
            text-transform: uppercase;
            font-size: 0.75em;
            color: $gradient-color1;
            &:hover,
            &:focus {
                color: $gradient-color2;
            }
        }
        .logo {
            font-size: 2.5em;
            display: inline-flex;
            align-items: center;
            padding: 0 1rem;
            span {
                display: none;
            }
        }
        .close {
            display: inline-flex;
            align-items: center;
            line-height: 1;
            padding: 1rem;
            margin: 0;
            float: none;
            background: none;
            border: 0;
            border-radius: 0;
            i {
                font-size: 2em;
            }
            &>*:not(:last-child) {
                margin-right: .5em;
            }
        }
    }
    &--searchbox {
        .wrapper {
            width: 100%;
            height: 80%;
            display: flex;
            align-items: center;
            justify-content: center;
            .container {
                width: 100%;
                max-width: 1280px;
                padding: 1rem;
                h1 {
                    color: $gradient-color1;
                }
            }
        }
    }
    &--navigation {
        overflow-y: scroll;
        padding-bottom: 4rem;
        &::-webkit-scrollbar {
            display: none;
        }
        > ul{
            > li{
                display: none;
            }
        }
        &[data-show="communities"],
        &[data-show="explore"]{
            background: $background-color;
            ul{
                list-style: none;
                padding: 0;
                margin: 0;
                a{
                    color: $text-color;
                }
            }
            > ul {
                > li{
                    > a{
                        display: none;
                    }
                    > ul {
                        > li{
                            > a{
                                position: relative;
                                display: block;
                                font-family: $heading-font-family;
                                font-weight: $heading-font-weight;
                                border-bottom: 1px solid #ddd;
                                padding: 1rem;
                                pointer-events: none;
                                > .accordion-trigger{
                                    position: absolute;
                                    top: 0;
                                    bottom: 0;
                                    left: 0;
                                    right: 0;
                                    display: flex;
                                    justify-content: flex-end;
                                    pointer-events: all;
                                    cursor: pointer;
                                    &:after{
                                        content: '\f2fb';
                                        font-size: 2em;
                                        font-family: $zmdi;
                                        line-height: 1;
                                        display: flex;
                                        align-items: center;
                                        padding: 1rem 1.25rem;
                                    }
                                }
                            }
                            > ul{
                                display: none;
                                font-family: .85em;
                                > li{
                                    padding: 1rem 1rem .5rem;
                                    border-bottom: 1px dotted #ddd;
                                    @include media("tablet", min){
                                        flex-basis: 50%;
                                        /* IE ONLY */
                                        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none){
                                            flex-basis: calc(50% - 2rem);
                                        }
                                    }
                                    > a {
                                        font-family: $heading-font-family;
                                        font-weight: $heading-font-weight;
                                        margin-bottom: .5rem;
                                        display: block;
                                    }
                                    > ul{
                                        > li{
                                            > a{
                                                display: block;
                                                padding: .5rem 0;
                                            }
                                            @mixin cpbulbaNavSecIconsMobile($id, $icon) {
                                                &[data-icon="#{$id}"] {
                                                    >a {
                                                        display: flex;
                                                        align-items: center;
                                                        &:before {
                                                            content: unicode($icon);
                                                            font-family: $cpicons;
                                                            margin-right: .5em;
                                                            font-size: 1.75em;
                                                        }
                                                    }
                                                }
                                            }
                                            @each $id,
                                            $data in $sector-icons {
                                                @include cpbulbaNavSecIconsMobile($id, $data);
                                            }
                                        }
                                    }
                                }
                                &[data-scheme="sectors"] {
                                    @mixin cpbulbaNavSecColoursMobile($id, $color1, $color2) {
                                        li[data-color="#{$id}"] {
                                            >a {
                                                color: $color1;
                                            }
                                            >ul {
                                                >li {
                                                    >a {
                                                        &:before {
                                                            color: $color1;
                                                        }
                                                        &:hover,
                                                        &:focus {
                                                            color: $color1;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    @each $id,
                                    $data in $sector-scheme {
                                        @include cpbulbaNavSecColoursMobile($id, nth($data, 1), nth($data, 2));
                                    }
                                }
                            }
                            @mixin cpbulbaNavCommunities($id, $color1, $color2) {
                                &[data-color="#{$id}"] {
                                    > a{
                                        color: $color1;
                                    }
                                    >ul {
                                        background: $color1;
                                        a {
                                            color: #fff;
                                            &:focus,
                                            &:hover {
                                                color: $color2;
                                            }
                                        }
                                    }
                                }
                            }
                            @each $id,
                            $data in $groups-scheme {
                                @include cpbulbaNavCommunities($id, nth($data, 1), nth($data, 2));
                            }
                            &.disabled{
                                display: none;
                            }
                            &.active{
                                > a{
                                    text-align: center;
                                    pointer-events: all;
                                    > .accordion-trigger{
                                        justify-content: flex-start;
                                        right: auto;
                                        &:after{
                                            content: '\f2fa';
                                        }
                                    }
                                }
                                > ul{
                                    display: block;
                                    @include media("tablet", min){
                                        display: flex;
                                        flex-wrap: wrap;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        &[data-show="communities"]{
            > ul{
                > li[data-id="communities"]{
                    display: block;
                }
            }
        }
        &[data-show="explore"]{
            > ul{
                > li[data-id="explore"]{
                    display: block;
                }
            }
        }
        &[data-show="media"],
        &[data-show="tools"]{
            background: $background-color;
            ul{
                list-style: none;
                padding: 0;
                margin: 0;
            }
            > ul{
                > li{
                    > a{
                        display: block;
                        padding: 1rem;
                        border-bottom: 1px solid #ddd;
                        background: $background-color;
                        pointer-events: none;
                        color: $text-color;
                        font-family: $heading-font-family;
                        font-weight: $heading-font-weight;
                        text-align: center;
                    }
                    > ul{
                        > li{
                            padding: 1rem;
                            &:not(:last-child){
                                border-bottom: 1px dotted #ddd;
                            }
                            > a{
                                pointer-events: none;
                                display: block;
                                color: $text-color;
                                font-family: $heading-font-family;
                                font-weight: $heading-font-weight;
                                margin-bottom: 1rem;
                            }
                            > ul{
                                display: flex;
                                flex-wrap: wrap;
                                > li{
                                    flex-basis: calc(100%/3);
                                    text-align: center;
                                    line-height: 1;
                                    font-size: .85em;
                                    overflow: hidden;
                                    > a{
                                        display: block;
                                        padding: 1rem;
                                        color: $text-color;
                                        &:before{
                                            font-family: $cptoolbox;
                                            display: block;
                                            font-size: 3em;
                                            margin-bottom: .5rem;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        @mixin cpbulbaNavAppsIconsMobile($id, $icon, $color) {
            &[data-icon="#{$id}"] {
                >a {
                    &:before {
                        color: $color;
                        content: unicode($icon);
                    }
                }
                @if ($id == facebook) or ($id == instagram) or ($id == twitter) or ($id == youtube) {
                    > a{
                        &:before{
                            font-family: $zmdi;
                        }
                    }
                }
            }
        }
        &[data-show="media"]{
            > ul{
                > li[data-id="media"]{
                    display: block;
                    > ul{
                        > li{
                            > ul{
                                > li{
                                    @each $id,
                                    $data in $media-icons {
                                        @include cpbulbaNavAppsIconsMobile($id, nth($data, 1), nth($data, 2));
                                    }
                                    @each $id,
                                    $data in $social-icons {
                                        @include cpbulbaNavAppsIconsMobile($id, nth($data, 1), nth($data, 2));
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        &[data-show="tools"]{
            > ul{
                > li[data-id="tools"]{
                    display: block;
                    > ul{
                        > li{
                            > ul{
                                > li{
                                    @each $id,
                                    $data in $apps-icons {
                                        @include cpbulbaNavAppsIconsMobile($id, nth($data, 1), nth($data, 2));
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.agent--ipad--12,
.agent--ipad--11,
.agent--ipad--10,
.agent--iphone--12,
.agent--iphone--11,
.agent--iphone--10 {
    .cpbulba-overlay{
        position: absolute;
        top: 4rem;
        height: auto;
        box-shadow: 0 1rem 1rem rgba(#000, 0.15);
        background: rgba($background-color2, 0.95);
        .wrapper{
            height: auto;
        }
        &--searchbox {
            .wrapper {
                width: 100%;
                height: auto;
                display: block;
            }
        }
    }
}