$sector-icons: (
    1:  ($cp-advertising-icon),
    2:  ($cp-farming-icon),
    3:  ($cp-art-icon),
    4:  ($cp-architecture-icon),
    5:  ($cp-business-icon),
    6:  ($cp-biological-icon),
    7:  ($cp-government-icon),
    8:  ($cp-computers-icon),
    9:  ($cp-clerical-icon),
    10: ($cp-education-icon),
    11: (),
    12: ($cp-music-icon),
    13: ($cp-fashion-icon),
    14: ($cp-banking-icon),
    15: ($cp-medical-icon),
    16: ($cp-tourism-icon),
    17: ($cp-law-icon),
    18: ($cp-leisure-icon),
    19: ($cp-engineering-icon),
    20: ($cp-media-icon),
    21: ($cp-physics-icon),
    22: ($cp-sales-icon),
    23: ($cp-security-icon),
    24: ($cp-psychology-icon),
    25: ($cp-food-icon),
    26: ($cp-insurance-icon),
    27: ($cp-transport-icon),
    28: (),
    29: ($cp-maritime-icon),
    30: ($cp-history-icon),
    31: ($cp-animals-icon),
    32: ($cp-accountancy-icon),
    33: ($cp-earth-icon),
    34: ($cp-biomedical-icon),
    35: (),
    36: ($cp-community-icon),
) !default;

$interest-icons: (
  1: ($cp-realist-icon, $realist-color),
  2: ($cp-administrative-icon, $administrative-color),
  3: ($cp-enterprising-icon, $enterprising-color),
  4: ($cp-investigative-icon, $investigative-color),
  5: ($cp-social-icon, $social-color),
  6: ($cp-creative-icon, $creative-color),
  7: ($cp-linguistic-icon, $linguistic-color),
  8: ($cp-naturalist-icon, $naturalist-color)
) !default;

$media-icons: (
    mediahub:   ($cptoolbox-media-hub, #21D4FD),
    articles:   ($cptoolbox-career-articles, #21D4FD),
    calendar:   ($cptoolbox-guidance-calendar, #21D4FD),
    interviews: ($cptoolbox-career-interviews, #21D4FD),
    news:       ($cptoolbox-in-the-news, #21D4FD),
    videos:     ($cptoolbox-videos, #21D4FD),
) !default;

$social-icons: (
    facebook:  (\f343, #3B5998),
    instagram: (\f34f, #FB3958),
    twitter:   (\f360, #1DA1F2),
    youtube:   (\f408, #FF0000), 
)  !default;

$apps-icons: (
    apprenticeships:           ($cptoolbox-apprenticeships, #487B7C),
    cao-points-calculator:     ($cptoolbox-points-calculator, #306189),
    cao-points-calculator-qqi: ($cptoolbox-points-calculator, #5686A2),
    career-explorer:           ($cptoolbox-career-explorer, #B2945B),
    college-profiles:          ($cptoolbox-college-profiles, #21D4FD),
    course-finder:             ($cptoolbox-course-finder, #1A8D9A),
    experts-and-employers:     ($cptoolbox-experts-employers, #21D4FD),
    fetch-courses:             ($cptoolbox-course-finder, #D9500B),
    graduate-recruitment-a-z:  ($cptoolbox-graduate-recruitment, #B63437),
    interest-profiler:         ($cptoolbox-career-interest-profiler, #21D4FD),
    jobs-in-demand:            ($cptoolbox-career-explorer, #B2945B),
    personality-quiz:          ($cptoolbox-personality-profiler, #21D4FD),
    scholarships:              ($cptoolbox-scholarships, #21D4FD),
    study-skills-test:         ($cptoolbox-study-skills-test, #21D4FD),
    subject-choice:            ($cptoolbox-subjects-choice, #487B7C),
    subject-grades-explorer:   ($cptoolbox-subject-grades, #487B7C),
    workxperience:             ($cptoolbox-workxperience, #FF6F61),
    sectors:                   ($cptoolbox-career-sectors, #21D4FD),
) !default;

$app: (
    1:  ($cptoolbox-career-interest-profiler, #21D4FD),
    2:  ($cptoolbox-personality-profiler, #21D4FD),
    3:  ($cptoolbox-career-sectors, #21D4FD),
    4:  ($cptoolbox-my-careerpad, #21D4FD),
    5:  ($cptoolbox-career-explorer, #B2945B),
    6:  ($cptoolbox-career-research, #21D4FD),
    7:  ($cptoolbox-videos, #21D4FD),
    8:  ($cptoolbox-job-hunting, #21D4FD),
    9:  ($cptoolbox-career-skills, #21D4FD),
    10: ($cptoolbox-course-finder, #1A8D9A),
    11: ($cptoolbox-workxperience, #FF6F61),
    12: ($cptoolbox-workxperience, #FF6F61),
    13: ($cptoolbox-study-skills-test, #21D4FD),
    14: ($cptoolbox-career-explorer, #B2945B),
    15: ($cptoolbox-course-finder, #D9500B),
    16: ($cptoolbox-colour-blind-test, #21D4FD),
    17: ($cptoolbox-apprenticeships, #487B7C),
    18: ($cptoolbox-course-finder, #1A8D9A),
    19: ($cptoolbox-job-applications, #21D4FD),
    20: ($cptoolbox-course-finder, #1A8D9A),
    21: ($cptoolbox-workxperience, #FF6F61),
    22: ($cptoolbox-course-finder, #1A8D9A),
    25: ($cptoolbox-personal-action-plan, #21D4FD),
    26: ($cptoolbox-career-docs, #21D4FD),
    30: ($cptoolbox-points-calculator, #21D4FD),
    32: ($cptoolbox-career-skills, #21D4FD),
    33: ($cptoolbox-media-hub, #21D4FD),
    34: ($cptoolbox-guidance-calendar, #21D4FD),
    35: ($cptoolbox-course-finder, #1A8D9A),
    36: ($cptoolbox-college-profiles, #21D4FD),
    37: ($cptoolbox-experts-employers, #21D4FD),
    38: ($cptoolbox-career-sectors, #21D4FD),
    39: ($cptoolbox-postgrad-study, #B63437),
    40: ($cptoolbox-careersportal-training-dates, #B63437),
    41: ($cptoolbox-career-interviews, #21D4FD),
) !default;

$reachtools: (
    //saoloibre
    65: ($cptoolbox-career-interest-profiler, $cao-color),
    66: ($cptoolbox-career-sectors, $cao-color),
    67: ($cptoolbox-career-explorer, $cao-color),
    64: ($cptoolbox-videos, $cao-color),
    //careerplaningandresearch
    32: ($cptoolbox-career-interviews, $adultlearner-color),
    2:  ($cptoolbox-career-interest-profiler, $adultlearner-color),
    13: ($cptoolbox-career-interest-profiler, $adultlearner-color),
    37: ($cptoolbox-career-interest-profiler, $adultlearner-color),
    1:  ($cptoolbox-career-interest-profiler, $adultlearner-color),
    38: ($cptoolbox-career-interest-profiler, $adultlearner-color),
    53: ($cptoolbox-videos, $adultlearner-color),
    20: ($cptoolbox-career-interest-profiler, $adultlearner-color),
    19: ($cptoolbox-guidance-calendar, $adultlearner-color),
    72: ($cptoolbox-videos, $adultlearner-color),
    74: ($cptoolbox-videos, $adultlearner-color),
    73: ($cptoolbox-videos, $adultlearner-color),
    55: ($cptoolbox-disability-info, $adultlearner-color),
    //selfassessment
    3:  ($cptoolbox-career-interest-profiler, $default-color),
    36: ($cptoolbox-self-assessment, $default-color),
    26: ($cptoolbox-personality-profiler, $default-color),
    39: ($cptoolbox-self-assessment, $default-color),
    40: ($cptoolbox-self-assessment $default-color),
    30: ($cptoolbox-career-skills, $default-color),
    48: ($cptoolbox-self-assessment, $default-color),
    54: ($cptoolbox-colour-blind-test, $default-color),
    //studyperformance
    7:  ($cptoolbox-subject-grades, $healthcare-color),
    41: ($cptoolbox-career-interest-profiler, $healthcare-color),
    6:  ($cptoolbox-study-skills-test, $healthcare-color),
    9:  ($cptoolbox-personal-action-plan, $healthcare-color),
    //worldofeducation
    23: ($cptoolbox-leaving-cert-next-steps, $plc-color),
    18: ($cptoolbox-leaving-cert-next-steps, $plc-color),
    58: ($cptoolbox-points-calculator, $plc-color),
    27: ($cptoolbox-subjects-choice, $plc-color),
    69: ($cptoolbox-course-finder, $plc-color),
    21: ($cptoolbox-personal-action-plan, $plc-color),
    57: ($cptoolbox-subject-grades, $plc-color),
    68: ($cptoolbox-scholarships, $plc-color),
    70: ($cptoolbox-college-profiles, $plc-color),
    71: ($cptoolbox-subject-grades, $plc-color),
    //worldofwork
    22: ($cptoolbox-career-sectors, $wx-color),
    15: ($cptoolbox-career-explorer, $wx-color),
    59: ($cptoolbox-apprenticeships, $wx-color),
    16: ($cptoolbox-labour-market-information, $wx-color),
    29: ($cptoolbox-workxperience, $wx-color),
    33: ($cptoolbox-job-applications, $wx-color),
    50: ($cptoolbox-subject-grades, $wx-color),
    4:  ($cptoolbox-job-hunting, $wx-color),
    43: ($cptoolbox-personal-action-plan, $wx-color),
    44: ($cptoolbox-subject-grades, $wx-color),
    42: ($cptoolbox-subject-grades, $wx-color),
    46: ($cptoolbox-subject-grades, $wx-color),
);

$zone: (
    1:  ($cpzone-1, #D3315B),
    2:  ($cpzone-2, #F89748),
    3:  ($cpzone-3, #FEBE0F),
    4:  ($cpzone-4, #CEBF2B),
    5:  ($cpzone-5, #9EBF3B),
) !default;

$level: (
    0:  ($cplevel-0,   #DDDDDD),
    1:  ($cplevel-1,   #D1335B),
    2:  ($cplevel-2,   #DF4C55),
    3:  ($cplevel-3,   #E96550),
    4:  ($cplevel-4,   #F57F4A),
    5:  ($cplevel-5,   #F89748),
    6:  ($cplevel-6,   #FEBE0F),
    7:  ($cplevel-7,   #E7BF1F),
    8:  ($cplevel-8,   #CEBF2B),
    9:  ($cplevel-9,   #B6BF34),
    10: ($cplevel-10, #9EBF3B),
) !default;