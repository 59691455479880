// ----------------------------------------
// CP Zone Icon Font
// ----------------------------------------
$cpzone-family: "CPZone" !default;
$cpzone-0: \e900;
$cpzone-1: \e901;
$cpzone-2: \e902;
$cpzone-3: \e903;
$cpzone-4: \e904;
$cpzone-5: \e905;
@font-face {
    font-family: '#{$cpzone-family}';
    src: url('#{$fontpath}/#{$cpzone-family}.eot?9eagt1');
    src: url('#{$fontpath}/#{$cpzone-family}.eot?9eagt1#iefix') format('embedded-opentype'),
    url('#{$fontpath}/#{$cpzone-family}.ttf?9eagt1') format('truetype'),
    url('#{$fontpath}/#{$cpzone-family}.woff?9eagt1') format('woff'),
    url('#{$fontpath}/#{$cpzone-family}.svg?9eagt1##{$cpzone-family}') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

[class^="cpzone-"],
[class*=" cpzone-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: '#{$cpzone-family}' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.cpzone-0 {
    &:before {
        content: unicode($cpzone-0);
    }
}

.cpzone-1 {
    &:before {
        content: unicode($cpzone-1);
    }
}

.cpzone-2 {
    &:before {
        content: unicode($cpzone-2);
    }
}

.cpzone-3 {
    &:before {
        content: unicode($cpzone-3);
    }
}

.cpzone-4 {
    &:before {
        content: unicode($cpzone-4);
    }
}

.cpzone-5 {
    &:before {
        content: unicode($cpzone-5);
    }
}