.cpbulba-searchbox {
    max-width: 1280px;
    margin: auto;
    box-sizing: border-box;
    position: relative;
    ul {
        display: flex;
        overflow: hidden;
        list-style: none;
        margin: 0;
        padding: 0;
        li {
            transition: all 150ms ease-in-out;
            user-select: none;
            cursor: pointer;
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 1rem 1.5rem;
            font-family: $heading-font-family;
            font-weight: $heading-font-weight;
            border-top-left-radius: 1rem;
            border-top-right-radius: 1rem;
            background: $acqua;
            color: $gradient-color1;
            font-size: .95em;
            text-align: center;
            @include media("tablet-wide", max) {
                padding: 1rem .5rem;
                font-size: 0.7rem;
                border-top-left-radius: 0;
                border-top-right-radius: 0;
                &:first-child{
                    border-top-left-radius: 1rem;
                }
                &:last-child{
                    border-top-right-radius: 1rem;
                }
            }
            &:not(:first-child) {
                border-left: 1px solid darken($acqua, 5%);
            }
            &:hover {
                background: #f2f2f2;
                border-color: #f2f2f2;
                &+li {
                    border-color: transparent;
                }
            }
            &.active {
                position: relative;
                cursor: auto;
                background: #fff;
                box-shadow: 0 1rem 2rem 0 rgba(#000, 0.15);
                border-color: transparent;
                &+li {
                    border-color: transparent;
                }
            }
        }
    }
    .tabs {
        padding: 2rem;
        border-bottom-left-radius: 1rem;
        border-bottom-right-radius: 1rem;
        box-shadow: 0 1rem 2rem 0 rgba(#000, 0.15);
        background: #fff;
        @include media("tablet-wide", max) {
            padding: 1rem;
        }
        .search-form {
            &:not(.active) {
                display: none;
            }
            form {
                display: flex;
                align-items: center;
                @include media("tablet-wide", max) {
                    flex-wrap: wrap;
                }
                .input-field {
                    &:not(:last-child) {
                        margin-right: 1rem;
                    }
                    select,
                    input {
                        box-sizing: border-box;
                        width: 100%;
                        border: 1px solid #ddd;
                        background: #f7f7f7;
                        padding: 0.75rem 1.25rem;
                        outline: 0;
                        border-radius: 2rem;
                        color: $text-color;
                        box-shadow: none;
                        &:focus {
                            border-color: $acqua;
                        }
                    }
                    &--text {
                        flex: 1;
                        @include media("tablet-wide", max) {
                            min-width: 100%;
                            margin-bottom: 1rem;
                            flex: 0;
                        }
                    }
                    &--select {
                        position: relative;
                        @include media("tablet-wide", max) {
                            min-width: 100%;
                            margin-bottom: 1rem;
                            flex: 0;
                        }
                        select {
                            appearance: none;
                            padding-right: 2.25rem;
                            &::-ms-expand {
                                display: none;
                            }
                        }
                        &:after {
                            pointer-events: none;
                            content: '\f2f9';
                            font-family: $zmdi;
                            position: absolute;
                            top: 50%;
                            transform: translateY(-50%);
                            right: 1rem;
                            font-size: 1.5rem;
                            color: $gradient-color1;
                        }
                        @include media("tablet-wide", max) {
                            flex: 1;
                        }
                    }
                    &--submit {
                        @include media("tablet-wide", max) {
                            min-width: 100%;
                        }
                        input {
                            cursor: pointer;
                            font-family: $heading-font-family;
                            font-weight: $heading-font-weight;
                            text-transform: uppercase;
                            font-size: 0.85rem;
                            padding: 0.8rem 1.5rem;
                            letter-spacing: .15rem;
                            background: $gradient-color1;
                            color: #fff;
                            border-color: $gradient-color1;
                            transition: all 150ms ease-in-out;
                            &:hover {
                                letter-spacing: .25rem;
                            }
                            &:focus {
                                border-color: $gradient-color1;
                                letter-spacing: .25rem;
                            }
                        }
                    }
                }
            }
            &[data-id="google"] {
                .gsc-search-box {
                    margin: 0;
                    table {
                        tbody {
                            tr {
                                display: flex;
                                align-items: center;
                                @include media("tablet-wide", max) {
                                    flex-wrap: wrap;
                                }
                            }
                        }
                    }
                    .gsc-input {
                        flex: 1;
                        width: 100%;
                        @include media("tablet-wide", max) {
                            min-width: 100%;
                            flex: 0;
                            padding-right: 0;
                        }
                        _:-webkit-full-screen:not(:root:root),
                        & {
                            /*
                            Define here the CSS styles applied only to the Safari 9 version when
                            not running in iOS devices (iPhone, iPad, ...)
                            */
                            flex: 5.5;
                        }
                        .gsc-input-box {
                            box-sizing: border-box;
                            width: 100%;
                            border: 1px solid #ddd;
                            background: #f7f7f7;
                            padding: 0.75rem 1.25rem;
                            outline: 0;
                            border-radius: 2rem;
                            color: $text-color;
                            table {
                                tbody {
                                    tr {
                                        display: flex;
                                        align-items: center;
                                    }
                                }
                            }
                            @include media("tablet-wide", max) {
                                margin-bottom: 1rem;
                            }
                            &:hover {
                                border-color: $acqua;
                            }
                            .gsib_a {
                                padding: 0;
                                flex: 1;
                                input {
                                    background: #f7f7f7;
                                    font-size: 1em;
                                }
                            }
                            .gsst_a {
                                display: flex;
                                align-items: center;
                                padding: 0;
                                .gscb_a {
                                    line-height: 0;
                                    color: $gradient-color1;
                                    &:hover,
                                    &:focus {
                                        color: $gradient-color1;
                                    }
                                }
                            }
                        }
                    }
                    .gsc-search-button {
                        width: auto;
                        flex: 0;
                        @include media("tablet-wide", max) {
                            min-width: 100%;
                        }
                        _:-webkit-full-screen:not(:root:root),
                        & {
                            /*
                            Define here the CSS styles applied only to the Safari 9 version when
                            not running in iOS devices (iPhone, iPad, ...)
                            */
                            flex: 1;
                        }
                        .gsc-search-button-v2 {
                            white-space: nowrap;
                            box-sizing: border-box;
                            width: 100%;
                            border: 1px solid $gradient-color1;
                            background: #f7f7f7;
                            outline: 0;
                            border-radius: 2rem;
                            cursor: pointer;
                            font-family: $heading-font-family;
                            font-weight: $heading-font-weight;
                            text-transform: uppercase;
                            font-size: 0.85rem;
                            padding: 0.8rem 1.5rem;
                            letter-spacing: .15rem;
                            background: $gradient-color1;
                            color: #fff;
                            transition: all 150ms ease-in-out;
                            &:hover {
                                letter-spacing: .25rem;
                            }
                            &:focus {
                                border-color: $gradient-color1;
                                letter-spacing: .25rem;
                                box-shadow: none;
                            }
                            svg {
                                display: none;
                            }
                            &:after {
                                content: 'Search Now';
                            }
                        }
                    }
                }
            }
        }
    }
}